












































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    CartItemChildType,
    CartTicket,
    PricingDetails,
    TicketEvent,
} from '@openticket/lib-shop';
import CartReviewTicketOptionalProduct from '@/pages/shop/components/CartReviewTicketOptionalProduct.vue';
import CartReviewTicketInfo from '@/pages/shop/components/CartReviewTicketInfo.vue';

@Component({
    components: { CartReviewTicketInfo, CartReviewTicketOptionalProduct },
})
export default class CartReviewTicket extends Vue {
    @Prop({ required: true }) eventId!: string;
    @Prop({ required: true }) ticketId!: string;

    ticket: TicketEvent | null = null;

    originalPricing: PricingDetails | null = null;

    ticketListener!: string;

    cartTickets: { [cartTicketId: string]: CartTicket } | null = null;

    isSeated = false;

    created(): void {
        this.ticketListener = this.$shop.cart.on(
            ['ticket', this.ticketId],
            (path: string[], details: TicketEvent) => {
                this.updateTicket(details);
            }
        );

        this.originalPricing = this.$shop.data.tickets_map[
            this.ticketId
        ].original_pricing;

        this.updateTicket(this.$shop.cart.getTicketInfo(this.ticketId));
    }

    updateTicket(details: TicketEvent): void {
        this.ticket = details;
        this.cartTickets = {
            ...this.$shop.cart.items[this.eventId][CartItemChildType.Tickets]
                .map[this.ticketId],
        };

        this.isSeated = this.$shop.data.tickets_map[this.ticketId].seated;
    }

    destroyed(): void {
        this.$shop.off(this.ticketListener);
    }
}
