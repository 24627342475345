












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Event } from '@openticket/lib-shop';
import { WAITING_LIST_ROUTE_NAME } from '@/utils/waitingList';

@Component
export default class EventGroupSoldOut extends Vue {
    @Prop() event!: Event;

    async navigateToWaitingList() {
        await this.$router.push({
            name: WAITING_LIST_ROUTE_NAME,
            query: {
                ...this.$route.query,
                waitingListEventId: this.event.guid,
            },
        });
    }
}
