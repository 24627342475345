
























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { LogLevel } from '@openticket/lib-shop';

@Component
export default class ShopHeader extends Vue {
    @Prop() title!: string;
    @Prop() index!: number;
    @Prop() pageCount!: number;
    @Prop({ type: Boolean, default: false }) skipAction!: boolean;

    @Action('prevPage') prevPage!: () => void;

    previousRoute: any = null;
    lastEvent: string | null = null;
    scrolled = false;
    showCloseBtn = false;

    async created(): Promise<void> {
        this.routeChanged();
        window.addEventListener('scroll', this.handleScroll);

        this.$nextTick(async () => {
            if (this.$shop.messaging) {
                try {
                    await this.$shop.messaging.connecting;

                    const isRecord = (
                        obj: unknown
                    ): obj is Record<string, unknown> =>
                        typeof obj === 'object';

                    this.showCloseBtn =
                        !!this.$shop.messaging.client &&
                        isRecord(this.$shop.messaging.client) &&
                        this.$shop.messaging.client['type'] === 'popup';
                } catch (e) {
                    this.$shop.log.create(
                        LogLevel.Error,
                        ['shop-header', 'popup', 'init', 'failed'],
                        { error: e }
                    );
                }
            }
        });
    }

    back(): void {
        if (this.skipAction) {
            this.$emit('back');
        } else {
            this.prevPage();
        }
    }

    close(): void {
        if (this.$shop.messaging) {
            try {
                this.$shop.messaging.sendCustomMessage('closePopup', {
                    type: 'closePopup',
                });
            } catch (e) {
                this.$shop.log.create(
                    LogLevel.Error,
                    ['shop-header', 'popup', 'close', 'failed'],
                    { error: e }
                );
            }
        }
    }

    handleScroll(): void {
        // when the user scrolls, check the pageYOffset
        if (window.pageYOffset > 0) {
            // user is scrolled
            if (!this.scrolled) this.scrolled = true;
        } else {
            // user is at top of page
            if (this.scrolled) this.scrolled = false;
        }
    }

    get facebookPageUrl(): string | null {
        const url = this.$shop.data.facebook_page_url;
        if (!url || url === '') {
            return null;
        }
        return url;
    }

    @Watch('$route')
    routeChanged(): void {
        if (!this.$route.meta) {
            return;
        }

        const progress = this.$route.meta.progress;
        let route;

        switch (progress) {
            case 2:
                this.lastEvent = this.$router.currentRoute.params.event_id;
                route = { name: 'events' };
                break;
            case 3:
                if (this.lastEvent) {
                    route = {
                        name: 'tickets',
                        params: {
                            event_id: this.lastEvent,
                        },
                    };
                } else {
                    route = { name: 'events' };
                }
                break;
            case 4:
                route = { name: 'information' };
                break;
            default:
                route = null;
        }

        this.previousRoute = route;
    }
}
