










































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    CartPaymentMethod,
    PaymentMethod,
    PaymentMethodIssuer,
    PaymentMethodPricing,
} from '@openticket/lib-shop';
import PaymentMethodItemIcon from './PaymentMethodItemIcon.vue';
import { getSelectorClass } from '@/utils';

@Component({
    components: {
        PaymentMethodItemIcon,
    },
})
export default class PaymentMethodItem extends Vue {
    @Prop() method!: PaymentMethod;
    @Prop({ type: Boolean }) selected!: boolean;
    @Prop({ type: Boolean }) hideFees!: boolean;
    @Prop() selectedMethod!: CartPaymentMethod;
    @Prop() selectedIssuer!: PaymentMethodIssuer;

    get currency(): string {
        return this.$shop.data.currency || 'XXX';
    }

    get hasSpecificFees(): boolean {
        return (
            !!this.methodPricing.fees.specific &&
            !!(
                this.methodPricing.fees.specific.fixed ||
                this.methodPricing.fees.specific.percentage
            )
        );
    }

    get isSurcharged(): boolean {
        return (
            this.method.surchargePaymentMethodGuid ===
            this.selectedMethod.item.guid
        );
    }

    get methodPricing(): PaymentMethodPricing {
        if (this.isSurcharged) {
            return this.selectedMethod.item.pricing;
        }

        return this.method.pricing;
    }

    get surchargeReason(): string {
        if (this.selectedMethod.item.surchargeReason) {
            return this.selectedMethod.item.surchargeReason;
        }

        return 'shop.components.payment_method_item.surcharge.default';
    }

    get paymentMethodFeeSlug(): string {
        if (this.methodPricing.fees.specific) {
            if (this.methodPricing.fees.specific.fixed !== 0) {
                if (this.methodPricing.fees.specific.percentage !== 0) {
                    return 'shop.components.payment_method_item.fees.fixed_percentage';
                } else {
                    return 'shop.components.payment_method_item.fees.fixed';
                }
            }

            if (this.methodPricing.fees.specific.percentage !== 0) {
                return 'shop.components.payment_method_item.fees.percentage';
            }
        }

        return 'shop.components.payment_method_item.fees.none';
    }

    private get paymentMethodSelectorClass(): string {
        return getSelectorClass('payment-method', this.method.guid);
    }

    created(): void {
        this.renderPaymentMethodItemExtra();
    }

    get options(): { [key: string]: string } {
        const map: { [key: string]: string } = {};
        for (const issuer of this.method.issuers) {
            map[issuer.id] = issuer.name;
        }
        return map;
    }

    onClick(): void {
        // Prevent the surcharge payment method from being unselected.
        if (this.selected && this.isSurcharged) {
            return;
        }

        this.$emit('click');
    }

    stripe(): void {
        this.$nextTick(async () => {
            await this.$shop.cart.mountStripePaymentMethod('#stripe-container');
        });
    }

    cse(): void {
        this.$nextTick(async () => {
            if (!this.method.serviceId || !this.method.tokenUrl) {
                return;
            }

            await this.$shop.cart.cse?.mountCSEPaymentMethod(
                '#cse-container',
                this.method.serviceId,
                this.method.tokenUrl
            );
        });
    }

    renderPaymentMethodItemExtra(): void {
        if (this.selected && this.method.stripe) {
            this.stripe();
        }
        if (this.selected && this.method.cse) {
            this.cse();
        }
    }

    selectIssuer(guid: string): void {
        this.$emit('set-issuer', guid);
    }

    @Watch('selected')
    onSelectedChange(): void {
        this.renderPaymentMethodItemExtra();
    }
}
