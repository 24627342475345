


































import Component from 'vue-class-component';
import TicketMetadataGroup from '../components/TicketMetadataGroup.vue';
import { CartTicket } from '@openticket/lib-shop';
import ShopModule, {
    ShopModuleValidationError,
    ShopModuleNotReady,
} from './module';
import { OpenTicketShop, PricingMethod } from '@openticket/lib-shop';

@Component({
    components: {
        TicketMetadataGroup,
    },
})
export default class TicketMetadata extends ShopModule {
    /* SHOP MODULE */

    public isDirty = true;

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        const metadata = this.$shop.cart.validator.allTicketMetadata(mutate);
        const products = this.$shop.cart.validator.allTicketProducts(mutate);

        if (mutate && (!metadata.valid || !products.valid)) {
            const errors = !metadata.valid ? metadata.errors : products.errors;
            const ticketGuid = Object.keys(errors)[0];

            if (ticketGuid) {
                const ticket = this.$shop.cart.flatItems.tickets[ticketGuid];

                if (ticket) {
                    this.openedTicket = ticket;
                }
            }
        }

        if (!metadata.valid) {
            return {
                message: metadata.message,
                payload: metadata.errors,
            };
        }

        if (!products.valid) {
            return {
                message: products.message,
                payload: products.errors,
            };
        }

        return null;
    }

    public static isReady(shop: OpenTicketShop): ShopModuleNotReady | null {
        if (Object.keys(shop.cart.flatItems.tickets).length > 0) {
            return null;
        }

        const products = Object.values(shop.cart.flatItems.products);

        for (const product of products) {
            if (product.item.pricing_method === PricingMethod.Own) {
                return null;
            }
        }

        return {
            scopes: ['tickets', 'products'],
            message:
                'shop.components.ticket_metadata.select_at_least_one_item_error',
        };
    }

    /* END SHOP MODULE */

    openedTicket: CartTicket | null = null;
    tickets: CartTicket[] = [];
    listener!: string;

    isValid = false;

    created(): void {
        this.tickets = this.getTickets();
        this.openedTicket = this.tickets[0];

        this.listener = this.$shop.cart.on(['ticket'], () => {
            const length = this.tickets.length;

            this.tickets = this.getTickets();

            if (length < 1 && this.tickets.length > 0) {
                this.openedTicket = this.tickets[0];
            }
        });
    }

    destroyed(): void {
        this.$shop.cart.off(this.listener);
    }

    getTickets(): CartTicket[] {
        return Object.values(this.$shop.cart.flatItems.tickets).filter(
            (cartTicket: CartTicket) => {
                return (
                    cartTicket.metadataList.length > 0 ||
                    cartTicket.item.products.optional.length > 0
                );
            }
        );
    }

    toggle(ticket: CartTicket): void {
        if (this.openedTicket === ticket) {
            this.openedTicket = null;
        } else {
            this.openedTicket = ticket;
        }
    }

    next(ticket: CartTicket): void {
        const result = this.$shop.cart.validator.ticketMetadata(ticket, true);
        if (!result) {
            return;
        }

        const index = this.tickets.indexOf(ticket);
        this.openedTicket = this.tickets[index + 1];
    }
}
