















import Vue from 'vue';
import Component from 'vue-class-component';
import { parseMarkdown } from '@/utils/parseMarkdown';

@Component
export default class MarkdownRenderer extends Vue {
    renderedContent!: string;

    created(): void {
        let slotContent = '';
        if (
            this.$slots &&
            'default' in this.$slots &&
            this.$slots.default &&
            Array.isArray(this.$slots.default) &&
            this.$slots.default.length > 0 &&
            typeof this.$slots.default[0] === 'object' &&
            'text' in this.$slots.default[0]
        ) {
            if (typeof this.$slots.default[0].text === 'string') {
                slotContent = this.$slots.default[0].text;
            } else {
                console.warn('Cannot render Markdown for non-string content');
            }
        }

        if (slotContent !== '') {
            this.renderedContent = parseMarkdown(slotContent);
        } else {
            this.renderedContent = '';
        }
    }
}
