































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Event, EventItem, Ticket } from '@openticket/lib-shop';
import EventGroupItemTicket from './EventGroupItemTicket.vue';
import CollapseCard from './CollapseCard.vue';
import { Prop } from 'vue-property-decorator';
import { getSelectorClass } from '@/utils';
import MarkdownRenderer from '@/pages/shop/components/MarkdownRenderer.vue';

@Component({
    components: {
        MarkdownRenderer,
        EventGroupItemTicket,
        CollapseCard,
    },
})
export default class EventGroupItem extends Vue {
    @Prop() event!: Event;
    @Prop() item!: EventItem;
    @Prop() exclude!: string[];
    @Prop() ticketFilter!: (ticket: Ticket) => boolean;

    get tickets(): Ticket[] {
        if (!this.ticketFilter) {
            return this.item.tickets;
        }

        const tickets: Ticket[] = [];
        for (const ticket of this.item.tickets) {
            if (this.ticketFilter(ticket)) {
                tickets.push(ticket);
            }
        }

        return tickets;
    }

    created(): void {
        if (this.item.type == 'collapse') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.opened = !!this.item.item.start_open;
        }
    }

    private get collapseSelectorClass(): string {
        return getSelectorClass('collapse', this.item.item.guid);
    }

    opened = true;

    toggle(): void {
        this.opened = !this.opened;
    }
}
