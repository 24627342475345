












































































































































































































































































import ShopModule from './module';
import { Component, Ref, Watch } from 'vue-property-decorator';
import {
    AddCouponEvent,
    CouponData,
    LogMessage,
    RemoveCouponEvent,
    UpdateCouponEvent,
} from '@openticket/lib-shop';

function errorTransform(error: LogMessage | any): string {
    const msg: string =
        error?.exception?.response?.data?.error_description || '';

    switch (msg) {
        case 'validation.extra.coupon.not_exists':
            return 'shop.components.coupon.error.not_exists';
        case 'validation.extra.coupon.valid':
            return 'shop.components.coupon.error.valid';
        case 'validation.extra.coupon.too_soon':
            return 'shop.components.coupon.error.too_soon';
        case 'validation.extra.coupon.too_late':
            return 'shop.components.coupon.error.too_late';
        case 'validation.extra.coupon.disabled':
            return 'shop.components.coupon.error.disabled';
        case 'validation.extra.coupon.exhausted':
            return 'shop.components.coupon.error.exhausted';
        case 'validation.extra.coupon.invalid':
        default:
            return error.slug || 'shop.components.coupon.error.invalid';
    }
}

@Component
export default class CouponModule extends ShopModule {
    @Ref('coupon')
    couponInputEl!: HTMLInputElement | undefined;

    // State props
    loading = false;
    showInput = false;
    hasCoupons = false;

    // Holding the error to show (not being shown if null)
    error: string | null = null;

    // Holding the input coupon and added coupons
    codeInput = '';
    coupons: { [code: string]: CouponData } = {}; // The SDK also supports multiple coupons already
    spend: { [code: string]: number } = {};

    couponListener: string | null = null;

    // Needed to ensure it is loaded as module
    public static isReady(): null {
        return null;
    }

    created(): void {
        const coupons = this.$shop.cart.getAppliedCoupons();
        for (const guid of Object.keys(coupons)) {
            const coupon = coupons[guid];
            this.coupons[coupon.item.code] = coupon.item.coupon;
            this.spend[coupon.item.code] = coupon.spend;
        }

        if (Object.keys(this.coupons).length > 0) {
            this.hasCoupons = true;
        }

        this.couponListener = this.$shop.cart.on(
            ['coupon'],
            (
                path: string[],
                data: AddCouponEvent | RemoveCouponEvent | UpdateCouponEvent
            ) => {
                switch (path[2]) {
                    case 'add':
                    case 'update':
                        if (!data.coupon) {
                            throw Error(
                                'Received no coupon object in coupon event'
                            );
                        }

                        this.spend = {
                            ...this.spend,
                            [data.code]: data.spend,
                        };

                        this.$set(this.coupons, data.code, data.coupon);
                        this.codeInput = '';
                        this.showInput = false;
                        this.hasCoupons = true;
                        break;

                    case 'remove':
                        this.$delete(this.spend, data.code);
                        this.$delete(this.coupons, data.code);
                        this.hasCoupons = false;
                        break;
                }
            }
        );
    }

    destroyed(): void {
        if (this.couponListener) {
            this.$shop.cart.off(this.couponListener);
        }
    }

    /**
     *  Used to show and programmatically focus the coupon input.
     */
    inputCoupon(): void {
        this.showInput = true;

        window.setTimeout(() => {
            if (this.couponInputEl) {
                this.couponInputEl.focus();
            }
        }, 100);
    }

    /**
     *  Used to add a coupon.
     */
    async addCoupon(): Promise<void> {
        if (!this.codeInput?.length) {
            return;
        }

        this.loading = true;

        try {
            await this.$shop.cart.addCoupon(this.codeInput);
        } catch (e) {
            this.error = errorTransform(e);
        } finally {
            this.loading = false;
        }
    }

    /**
     *  Used to remove a coupon.
     */
    async removeCoupon(code: string): Promise<void> {
        if (!code) {
            return;
        }

        this.loading = true;

        try {
            await this.$shop.cart.removeCoupon(code);
        } catch (e) {
            this.error = errorTransform(e);
        } finally {
            this.loading = false;
        }
    }

    @Watch('codeInput')
    onCodeInputChange(): void {
        this.error = null;
    }
}
