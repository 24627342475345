



















































import { LogLevel } from '@openticket/lib-shop';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

function getReferrer(): string {
    try {
        return window.document.referrer || '<hidden>';
    } catch (e) {
        return '<hidden>';
    }
}

@Component
export default class FrameBreak extends Vue {
    @Prop({ required: true, type: String })
    link!: string;

    created(): void {
        this.$shop.log.create(LogLevel.Info, ['frame-break'], {
            referrer: getReferrer(),
        });
    }
}
