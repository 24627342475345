import { Listener, ListenerSettings } from '@/pages/shop/listeners/listener';
import {
    CartItemChildType,
    Event,
    OpenTicketShop,
    Ticket,
} from '@openticket/lib-shop';
import { CustomShopSettingsDynamicListener } from '@openticket/lib-custom-shop-settings';
import { isNonEmptyCollection } from '@/utils/emptyCollection';
import { eventPassesFilter, ticketPassesFilter } from '@/utils/contentFilters';
import { ShopModuleShopContentModifiers } from '@/pages/shop/modules/module';

interface ListenerForceCartItemSettings extends ListenerSettings {
    force?: {
        // Ids of tickets to force when a product in the on prop is added
        ids?: string[];
        names?: string[];
        coupon?: string;
        on?: {
            all?: boolean;
            ids?: string[];
            names?: string[];
        };
    };
}

type FoundWithTicketAndEvent =
    | { found: true; event: Event; ticket: Ticket }
    | { found: false };

function compareItemMatchingListenerSpec(
    item: { guid: string; name: string },
    spec?: { ids?: string[]; names?: string[] }
): boolean {
    const lcName = item.name.toLowerCase();

    return (
        spec?.ids?.includes(item.guid) ||
        spec?.names?.some((criteria: string) =>
            lcName.includes(criteria.toLowerCase())
        ) ||
        false
    );
}

/**
 * The forced cart listener allows users to configure a listener that compels tickets to be added to a cart when
 * specific (or any) tickets in the shop are added. Upon removal of these "forced" tickets, all items associated
 * with them are also removed.
 *
 * (*) If multiple ids are forced they will all be required. Therefor if one is removed, all forced on ids are also
 * removed from cart
 *
 * (*) If you supply multiple forced listeners, make sure the all ids are unique between them to prevent unexpected and
 * unintended behavior
 */
export class ListenerForceCartItem extends Listener {
    static ownName = 'force-cart';

    private readonly cartListener?: string;
    private settings: ListenerForceCartItemSettings | null = null;
    private shopContentModifiers: {
        state: ShopModuleShopContentModifiers | null;
    };

    constructor(
        shop: OpenTicketShop,
        settings: CustomShopSettingsDynamicListener,
        shopContentModifiers: { state: ShopModuleShopContentModifiers | null }
    ) {
        super(shop);

        this.shopContentModifiers = shopContentModifiers;

        if (!this.validateSettings(settings)) {
            return;
        }
        this.settings = settings as ListenerForceCartItemSettings;

        this.cartListener = this.shop.events.on(
            ['cart', 'ticket'],
            (path: string[], data: any) => {
                if (path.length > 3) {
                    switch (path[3]) {
                        case 'add':
                            if (
                                this.isForcedOnCartTicket(data.guid, data.name)
                            ) {
                                this.forceCartTickets();
                                this.forceCouponCode();
                            }
                            break;
                        case 'remove':
                            if (
                                this.isForcedCartTicket(data.guid, data.name) &&
                                data.count < 1
                            ) {
                                this.removeForcedOnCartTicket();
                            }
                    }
                }
            }
        );
    }

    validateSettings(settings: CustomShopSettingsDynamicListener): boolean {
        if (
            !settings ||
            !(settings as ListenerForceCartItemSettings).force ||
            ((!(settings as ListenerForceCartItemSettings).force?.ids ||
                !(settings as ListenerForceCartItemSettings)?.force?.ids
                    ?.length) &&
                (!(settings as ListenerForceCartItemSettings).force?.coupon ||
                    !(settings as ListenerForceCartItemSettings)?.force?.coupon
                        ?.length))
        ) {
            // @todo: add warning to logger
            console.warn(
                'ListenerForceCart: Invalid settings, force cart item listener should have a forced id. Ignoring listener'
            );
            return false;
        }
        if (
            !settings ||
            !(settings as ListenerForceCartItemSettings).force ||
            !(settings as ListenerForceCartItemSettings).force?.on ||
            ((!(settings as ListenerForceCartItemSettings)?.force?.on?.ids ||
                !(settings as ListenerForceCartItemSettings)?.force?.on?.ids
                    ?.length) &&
                (!(settings as ListenerForceCartItemSettings)?.force?.on
                    ?.names ||
                    !(settings as ListenerForceCartItemSettings)?.force?.on
                        ?.names?.length) &&
                !(settings as ListenerForceCartItemSettings)?.force?.on?.all)
        ) {
            // @todo: add warning to logger
            console.warn(
                'ListenerForceCart: Invalid settings, force cart item listener should have a force on setting. Ignoring listener'
            );
            return false;
        }

        if (
            (settings as ListenerForceCartItemSettings)?.force?.on?.ids &&
            (settings as ListenerForceCartItemSettings)?.force?.on?.ids
                ?.length &&
            (settings as ListenerForceCartItemSettings)?.force?.on?.all
        ) {
            // @todo: add warning to logger
            console.warn(
                'ListenerForceCart: Force on all is set, the ids property will be ignored'
            );
        }

        if (
            (settings as ListenerForceCartItemSettings)?.force?.on?.names &&
            (settings as ListenerForceCartItemSettings)?.force?.on?.names
                ?.length &&
            (settings as ListenerForceCartItemSettings)?.force?.on?.all
        ) {
            // @todo: add warning to logger
            console.warn(
                'ListenerForceCart: Force on all is set, the names property will be ignored'
            );
        }

        return true;
    }

    ticketIsInCart(eventId: string, guid: string): boolean {
        return isNonEmptyCollection(
            this.shop.cart.items[eventId]?.[CartItemChildType.Tickets].map[guid]
        );
    }

    ticketIsInShopData(guid: string): FoundWithTicketAndEvent {
        const ticket: Ticket | undefined = this.shop.data.tickets_map[guid];

        if (!ticket) {
            return { found: false };
        }

        const event: Event | undefined = this.shop.data.events_map[
            ticket.event_id
        ];

        if (!event) {
            return { found: false };
        }

        return {
            found: true,
            event,
            ticket,
        };
    }

    ticketPassesFilterContentModifiers(event: Event, ticket: Ticket): boolean {
        if (!this.shopContentModifiers.state) {
            return true;
        }

        return (
            eventPassesFilter(this.shopContentModifiers.state, event) &&
            ticketPassesFilter(this.shopContentModifiers.state, ticket)
        );
    }

    // Check if the supplied guid is part of the forced set
    isForcedCartTicket(guid: string, name: string): boolean {
        return compareItemMatchingListenerSpec(
            {
                guid,
                name,
            },
            this.settings?.force
        );
    }

    // Check if the supplied guid is part of the forced on set
    isForcedOnCartTicket(guid: string, name: string): boolean {
        // Forced ticket cant also be a forced on ticket
        if (this.isForcedCartTicket(guid, name)) {
            return false;
        }

        if (this.settings?.force?.on?.all) {
            return true;
        }

        return compareItemMatchingListenerSpec(
            {
                guid,
                name,
            },
            this.settings?.force?.on
        );
    }

    // Checks if the forced tickets are in cart, if not add them (but only if they exist in the shop)
    forceCartTickets(): void {
        if (!this.settings?.force?.ids && !this.settings?.force?.names) {
            return;
        }

        Object.keys(this.shop.data.tickets_map).forEach((ticketId: string) => {
            const isInShopDataResult: FoundWithTicketAndEvent = this.ticketIsInShopData(
                ticketId
            );

            if (!isInShopDataResult.found) {
                return;
            }

            const isForced: boolean = compareItemMatchingListenerSpec(
                isInShopDataResult.ticket,
                this.settings?.force
            );

            if (
                !isForced ||
                isInShopDataResult.ticket.hidden ||
                !this.ticketPassesFilterContentModifiers(
                    isInShopDataResult.event,
                    isInShopDataResult.ticket
                ) ||
                this.ticketIsInCart(isInShopDataResult.event.guid, ticketId)
            ) {
                return;
            }

            void this.shop.cart.addTicket(ticketId);
        });
    }

    // Checks if the forced coupon is in the cart, if not, try to add it.
    forceCouponCode(): void {
        if (
            !this.settings?.force?.coupon ||
            Object.keys(this.shop.cart.getAppliedCoupons()).includes(
                this.settings.force.coupon
            )
        ) {
            return;
        }

        void this.shop.cart.addCoupon(this.settings.force.coupon);
    }

    removeForcedOnCartTicket(): void {
        if (this.settings?.force?.on?.all) {
            void this.shop.cart.removeAllItems();

            return;
        }

        if (
            !this.settings?.force?.on?.ids &&
            !this.settings?.force?.on?.names
        ) {
            return;
        }

        Object.entries(
            this.shop.cart.flatItems[CartItemChildType.Tickets]
        ).forEach(([key, ticket]) => {
            if (this.isForcedOnCartTicket(ticket.item.guid, ticket.item.name)) {
                void this.shop.cart.removeTicket(ticket.item.guid, key);
            }
        });
    }

    destroy(): void {
        if (this.cartListener) {
            this.shop.off(this.cartListener);
        }
    }
}
