
































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { PricingDetails } from '@openticket/lib-shop';

@Component
export default class CartReviewTicketInfo extends Vue {
    @Prop({ required: true }) ticketName!: string;
    @Prop({ required: true }) originalPricing!: PricingDetails;
    @Prop({ required: true }) pricing!: PricingDetails;

    @Prop({ default: null }) seatingInfo!: string | null;
    @Prop({ default: null }) counts!: { [price: number]: number } | null;

    protected get ticketFreePriceSlug(): string | null {
        if (this.$te('shop.components.shop_group_item.ticket.item_free')) {
            return 'shop.components.shop_group_item.ticket.item_free';
        }

        return 'shop.components.shop_group_item.item_free';
    }

    protected get ticketPriceSlug(): string | null {
        if (this.$te('shop.components.shop_group_item.ticket.item_price')) {
            return 'shop.components.shop_group_item.ticket.item_price';
        }

        return 'shop.components.shop_group_item.item_price';
    }

    get ticketFeeSlug(): string | null {
        if (this.$te('shop.components.shop_group_item.ticket.item_fee')) {
            return 'shop.components.shop_group_item.ticket.item_fee';
        }

        return 'shop.components.shop_group_item.item_fee';
    }

    protected get totalPriceSlug(): string {
        if (this.$te('shop.components.shop_group_item.ticket.price_total')) {
            return 'shop.components.shop_group_item.ticket.price_total';
        }

        return 'shop.components.shop_group_item.price_total';
    }
}
