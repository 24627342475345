import { ShopModuleShopContentModifiers } from '@/pages/shop/modules/module';
import { Event, Ticket } from '@openticket/lib-shop';

type CriteriaValue = string[] | ((target: string) => boolean);
type Criteria<T> = { [K in keyof T]?: CriteriaValue };

function objectMatchesCriteria<T>(obj: T, criteria: Criteria<T>): boolean {
    return (Object.entries(criteria) as Array<[keyof T, CriteriaValue]>).every(
        ([key, criteriaValues]) => {
            const value: unknown = obj[key];

            if (!value || typeof value !== 'string') {
                return false;
            }

            if (Array.isArray(criteriaValues)) {
                return criteriaValues.includes(value);
            }

            return criteriaValues.call(null, value);
        }
    );
}

function isInCriteriaList<T>(obj: T, criteriaList: Criteria<T>[]): boolean {
    return criteriaList.some(criteria => objectMatchesCriteria(obj, criteria));
}

function filterOnCriteriaFactory<T>(
    whitelists: Criteria<T>[] = [],
    blacklists: Criteria<T>[] = []
): (value: T) => value is T {
    return (value: T): value is T => {
        // If the object matches any blacklist criteria, exclude it
        if (isInCriteriaList(value, blacklists)) {
            return false;
        }

        // If there are no whitelists, include all that are not blacklisted
        if (whitelists.length === 0) {
            return true;
        }

        // Include the object if it matches any whitelist criteria
        return isInCriteriaList(value, whitelists);
    };
}

function partialComparisonFn(
    expectedList: string[]
): (target: string) => boolean {
    return (target: string) =>
        expectedList.some((expected: string) => target.includes(expected));
}

/**
 * Filters the supplied tickets according to the supplied filter
 * @param filter filter to filter on
 * @param contentToFilter
 */
export function filterShopContentTickets(
    filter: ShopModuleShopContentModifiers | null,
    contentToFilter: Ticket[]
): Ticket[] {
    if (filter === null || contentToFilter.length < 1) {
        return contentToFilter;
    }

    const whitelists = [
        ...(filter.whitelist?.ticket_ids
            ? [{ guid: filter.whitelist.ticket_ids }]
            : []),
        ...(filter.whitelist?.ticket_names
            ? [{ name: partialComparisonFn(filter.whitelist.ticket_names) }]
            : []),
    ];

    const blacklists = [
        ...(filter.blacklist?.ticket_ids
            ? [{ guid: filter.blacklist.ticket_ids }]
            : []),
        ...(filter.blacklist?.ticket_names
            ? [{ name: partialComparisonFn(filter.blacklist.ticket_names) }]
            : []),
    ];

    return contentToFilter.filter(
        filterOnCriteriaFactory(whitelists, blacklists)
    );
}

/**
 * Filters the supplied tickets according to the supplied filter
 * @param filter filter to filter on
 * @param contentToFilter
 */
export function filterShopContentEvents(
    filter: ShopModuleShopContentModifiers | null,
    contentToFilter: Event[]
): Event[] {
    if (filter === null || contentToFilter.length < 1) {
        return contentToFilter;
    }

    const whitelists = [
        ...(filter.whitelist?.event_ids
            ? [{ guid: filter.whitelist.event_ids }]
            : []),
        ...(filter.whitelist?.event_names
            ? [{ name: partialComparisonFn(filter.whitelist.event_names) }]
            : []),
    ];

    const blacklists = [
        ...(filter.blacklist?.event_ids
            ? [{ guid: filter.blacklist.event_ids }]
            : []),
        ...(filter.blacklist?.event_names
            ? [{ name: partialComparisonFn(filter.blacklist.event_names) }]
            : []),
    ];

    return contentToFilter.filter(
        filterOnCriteriaFactory(whitelists, blacklists)
    );
}

export function eventPassesFilter(
    filter: ShopModuleShopContentModifiers | null,
    event: { guid: string; name: string }
): boolean {
    if (filter === null) {
        return true;
    }

    const whitelists = [
        ...(filter.whitelist?.event_ids
            ? [{ guid: filter.whitelist.event_ids }]
            : []),
        ...(filter.whitelist?.event_names
            ? [{ name: partialComparisonFn(filter.whitelist.event_names) }]
            : []),
    ];

    const blacklists = [
        ...(filter.blacklist?.event_ids
            ? [{ guid: filter.blacklist.event_ids }]
            : []),
        ...(filter.blacklist?.event_names
            ? [{ name: partialComparisonFn(filter.blacklist.event_names) }]
            : []),
    ];

    return filterOnCriteriaFactory(whitelists, blacklists)(event);
}

export function ticketPassesFilter(
    filter: ShopModuleShopContentModifiers | null,
    ticket: { guid: string; name: string }
): boolean {
    if (filter === null) {
        return true;
    }

    const whitelists = [
        ...(filter.whitelist?.ticket_ids
            ? [{ guid: filter.whitelist.ticket_ids }]
            : []),
        ...(filter.whitelist?.ticket_names
            ? [{ name: partialComparisonFn(filter.whitelist.ticket_names) }]
            : []),
    ];

    const blacklists = [
        ...(filter.blacklist?.ticket_ids
            ? [{ guid: filter.blacklist.ticket_ids }]
            : []),
        ...(filter.blacklist?.ticket_names
            ? [{ name: partialComparisonFn(filter.blacklist.ticket_names) }]
            : []),
    ];

    return filterOnCriteriaFactory(whitelists, blacklists)(ticket);
}
