





































































































import Vue from 'vue';
import Component from 'vue-class-component';
import DatePicker from '../components/DatePicker.vue';
import { Collapse, EventDate, Ticket } from '@openticket/lib-shop';
import { Watch } from 'vue-property-decorator';
import { getSelectorClass } from '@/utils';

@Component({
    components: {
        DatePicker,
    },
})
export default class Timeslots extends Vue {
    loading = false;

    eventDateTicketCount: { [key: string]: number } = {};

    couponListener = '';

    created(): void {
        this.calculateTicketCount();

        // This is a fix for a specific problem (hide without coupon).
        // However, once updates on models are handled a bit more generalized,
        // this can also be handled better.
        this.couponListener = this.$shop.cart.on(['coupon'], () => {
            this.calculateTicketCount();
        });
    }

    destroyed(): void {
        this.$shop.cart.off(this.couponListener);
    }

    async onDateChange(start: string): Promise<void> {
        // Parsing and then formatting the end date are done a bit complex.
        // This is due to the date constructor considering a date string (2023-01-01) as UTC,
        // where a string containing time is evaluated as the user's local timezone...
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#sect2
        if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(start)) {
            throw new Error('Invalid start date');
        }

        const [y, m, d] = start.split('-').map((v: string) => parseInt(v, 10));
        const endDate = new Date(y, m - 1, d + 1);
        const end: string = [
            endDate.getFullYear(),
            endDate.getMonth() + 1,
            endDate.getDate(),
        ]
            .map((v: number) => v.toFixed(0).padStart(2, '0'))
            .join('-');

        this.loading = true;

        this.setEventDateQuery();

        await this.$shop.updateData({ start, end });

        this.calculateTicketCount();

        this.loading = false;
    }

    get show(): boolean {
        return (
            (!this.$settings ||
                !this.$settings.static ||
                !!this.$settings.static.shop.enableTimeslotsFilters) &&
            !this.loading
        );
    }

    get enabledDates(): any {
        return this.$shop.data.available_future_dates_nested;
    }

    get currentDate(): string {
        return this.$shop.data.filters.start;
    }

    get eventDates(): EventDate[] {
        return this.$shop.data.event_dates;
    }

    private getSelectorClass(eventDate: EventDate): string {
        return getSelectorClass('event-date', eventDate.guid);
    }

    calculateTicketCount(): void {
        this.eventDateTicketCount = {};

        for (const event of this.$shop.data.events) {
            for (const item of event.items) {
                if (item.type === 'collapse') {
                    for (const ticket of (item.item as Collapse).tickets) {
                        this.calculateIndividualTicketAvailable(ticket);
                    }
                } else {
                    this.calculateIndividualTicketAvailable(
                        item.item as Ticket
                    );
                }
            }
        }
    }

    calculateIndividualTicketAvailable(ticket: Ticket): void {
        if (
            !['available', 'not_sold_right_now'].includes(ticket.status) ||
            ticket.hidden
        ) {
            return;
        }

        for (const product of ticket.products.required) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (product.item.origin_type === 'date') {
                this.$set(
                    this.eventDateTicketCount,
                    product.item.guid,
                    (this.eventDateTicketCount[product.item.guid] || 0) + 1
                );
            }
        }
    }

    setEventDateFilter(eventDate: EventDate): void {
        if (!this.eventDateTicketCount[eventDate.guid]) {
            return;
        }

        const currentEventDate = this.$route.query.eventDate;
        if (currentEventDate !== eventDate.guid) {
            this.setEventDateQuery(eventDate.guid);
        }
    }

    setEventDateQuery(guid?: string): void {
        this.$router.replace({
            name: this.$route.name as string,
            params: this.$route.params,
            query: {
                ...this.$route.query,
                eventDate: guid,
            },
        });
    }

    @Watch('loading')
    onLoadingChange(): void {
        this.$emit('loading', this.loading);
    }
}
