import { OpenTicketShop } from '@openticket/lib-shop';

export interface ListenerSettings {
    name: string;
}

export abstract class Listener {
    static /* abstract */ ownName = '';

    protected shop: OpenTicketShop;
    protected constructor(shop: OpenTicketShop) {
        this.shop = shop;
    }

    abstract destroy(): void;
}
