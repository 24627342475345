
































































import TrackingClient, { GTMEvent } from '@openticket/lib-tracking';
import {
    BookerInfo,
    CartMetadata,
    CartTicket,
    MetadataType,
    OpenTicketShop,
} from '@openticket/lib-shop';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import ContactInformationQuickfill from '../components/ContactInformationQuickfill.vue';
import ShopModule, {
    ShopModuleNotReady,
    ShopModuleValidationError,
} from './module';

const STORAGE_BOOKER_INFO_KEY = 'booker_info';

@Component({
    components: {
        ContactInformationQuickfill,
    },
})
export default class BookerInformation extends ShopModule {
    /* SHOP MODULE */

    public scopes: string[] = ['booker_information'];

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        const response = this.$shop.cart.validator.bookerMetadata(mutate);

        if (response.valid) {
            return null;
        }

        return {
            message: response.message,
            payload: response.errors,
        };
    }

    public static isReady(shop: OpenTicketShop): ShopModuleNotReady | null {
        if (Object.keys(shop.cart.flatItems.tickets).length > 0) {
            return null;
        }

        return {
            scopes: ['tickets', 'products'],
            message: 'Select at least one item',
            slug:
                'shop.components.booker_information.select_at_least_one_item_error',
        };
    }

    /* END SHOP MODULE */

    @Prop() exclude!: string[];
    @Prop() providers!: string[] | null;

    @Inject('tracking')
    tracking!: TrackingClient;

    isComplete = false;

    get bookerInfo(): BookerInfo[] {
        return [
            this.$shop.cart.booker.firstname,
            this.$shop.cart.booker.lastname,
            this.$shop.cart.booker.email,
        ].filter(
            (metadata: BookerInfo) =>
                !this.exclude ||
                !this.exclude.includes(metadata.item.auto_fill_facebook)
        );
    }

    get metadata(): CartMetadata[] {
        return this.$shop.cart.metadataList.filter(
            (metadata: CartMetadata) =>
                !this.exclude || !this.exclude.includes(metadata.item.guid)
        );
    }

    created(): void {
        // Get autofill data from GET params
        const query = this.$route.query || {};
        const storage: any = {};

        // Fetch previously filled in metadata from localstorage
        this.bookerInfo.forEach((metadata: BookerInfo) => {
            const key = metadata.item.auto_fill_facebook;

            storage[metadata.item.auto_fill_facebook] =
                localStorage.getItem(`${STORAGE_BOOKER_INFO_KEY}_${key}`) ||
                undefined;
        });

        this.metadata.forEach((metadata: CartMetadata) => {
            const key = metadata.item.guid;

            storage[key] =
                localStorage.getItem(`${STORAGE_BOOKER_INFO_KEY}_${key}`) ||
                undefined;
        });

        // Autofill metadata from the given sources
        this.autofill({
            ...storage,
            ...query,
        });

        // Trigger an impression event for analytics
        const shop = {
            guid: this.$shop.data.guid as string,
            name: this.$shop.data.name as string,
        };

        const items = Object.values(this.$shop.cart.flatItems.tickets).map(
            (cartTicket: CartTicket) => ({
                id: cartTicket.item.guid,
                name: cartTicket.item.name,
                pricing: cartTicket.pricing,
            })
        );

        this.tracking.push([
            GTMEvent.details(shop, items),
            GTMEvent.checkout(shop, items, 1),
        ]);
    }

    /**
     * Autofill metadata from an object
     */
    autofill(profile: { [key: string]: any }): void {
        // Loop over all booker information
        for (const meta of this.bookerInfo) {
            // Only autofill values that are empty
            // Check if the autofill name matches
            if (
                (meta.value !== undefined && meta.value !== null) ||
                !profile[meta.item.auto_fill_facebook]
            ) {
                continue;
            }

            try {
                // Check if prefilled data is correct
                if (
                    this.$shop.cart.validator.metadata(
                        {
                            ...meta,
                            value: profile[meta.item.auto_fill_facebook],
                        },
                        false
                    ).valid
                ) {
                    meta.value = profile[meta.item.auto_fill_facebook];
                }
            } catch (_) {
                // TODO log
                // Silently fail
            }
        }

        // Loop over all booker metadata
        for (const meta of this.metadata) {
            // Only autofill values that are empty
            // Check if the autofill name matches
            if (
                (meta.value !== undefined && meta.value !== null) ||
                (!profile[meta.item.auto_fill_facebook] &&
                    !profile[meta.item.guid])
            ) {
                continue;
            }

            try {
                // Check if prefilled data is correct
                if (
                    profile[meta.item.auto_fill_facebook] &&
                    this.$shop.cart.validator.metadata(
                        {
                            ...meta,
                            value: profile[meta.item.auto_fill_facebook],
                        },
                        false
                    ).valid
                ) {
                    meta.value = profile[meta.item.auto_fill_facebook];
                } else if (
                    profile[meta.item.guid] &&
                    this.$shop.cart.validator.metadata(
                        { ...meta, value: profile[meta.item.guid] },
                        false
                    ).valid
                ) {
                    meta.value = profile[meta.item.guid];
                }
            } catch (_) {
                // TODO log
                // Silently fail
            }
        }

        this.isDirty = true;
    }

    next(): void {
        const result = this.$shop.cart.validator.bookerMetadata(true);
        if (result) {
            this.isComplete = true;
        }

        this.$emit('next');
    }

    onInputBookerInfo(metadata: BookerInfo): void {
        const key = metadata.item.auto_fill_facebook;

        // To prevent caching metadata such as 'keep me informed' (which is opt-in) and
        // 'I do not have symptoms of illness' kind of metadata.
        // DD-VUE-2717
        if (metadata.item.type !== MetadataType.Boolean) {
            localStorage.setItem(
                `${STORAGE_BOOKER_INFO_KEY}_${key}`,
                // Todo, refactor this `as string` to proper type checking
                metadata.value as string
            );
        }

        this.isDirty = true;
    }

    onInput(metadata: CartMetadata): void {
        const key = metadata.item.guid;

        // To prevent caching metadata such as 'keep me informed' (which is opt-in) and
        // 'I do not have symptoms of illness' kind of metadata.
        // DD-VUE-2717
        if (metadata.item.type !== MetadataType.Boolean) {
            localStorage.setItem(
                `${STORAGE_BOOKER_INFO_KEY}_${key}`,
                // Todo, refactor this `as string` to proper type checking
                metadata.value as string
            );
        }

        this.isDirty = true;
    }
}
