



































import ShopModuleComponent, { ShopModuleValidationError } from './module';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CartMetadata } from '@openticket/lib-shop';

@Component
export default class OrderMetadataItem extends ShopModuleComponent {
    /* SHOP MODULE */

    public scopes: string[] = ['booker_information'];

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        const response = this.$shop.cart.validator.metadata(
            this.metadata,
            mutate
        );

        if (response.valid) {
            return null;
        }

        return {
            message: response.message,
        };
    }

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Prop() guid!: string;

    get metadata(): CartMetadata {
        return this.$shop.cart.metadata[this.guid];
    }

    onInput(): void {
        this.isDirty = true;
    }
}
