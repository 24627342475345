import { render, staticRenderFns } from "./OrderBarTotal.vue?vue&type=template&id=40feeb54&scoped=true&"
import script from "./OrderBarTotal.vue?vue&type=script&lang=ts&"
export * from "./OrderBarTotal.vue?vue&type=script&lang=ts&"
import style0 from "./OrderBarTotal.vue?vue&type=style&index=0&id=40feeb54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40feeb54",
  null
  
)

export default component.exports