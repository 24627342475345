import { render, staticRenderFns } from "./EventCard.vue?vue&type=template&id=0e2016d2&scoped=true&"
import script from "./EventCard.vue?vue&type=script&lang=ts&"
export * from "./EventCard.vue?vue&type=script&lang=ts&"
import style0 from "./EventCard.vue?vue&type=style&index=0&id=0e2016d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e2016d2",
  null
  
)

export default component.exports