import urlJoin from 'url-join';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Rules } from 'validatorjs';
import { Event, Ticket } from '@openticket/lib-shop';

export const WaitingListTypes = {
    OneOrMoreTicketsSoldOut: 'one_or_more_tickets_sold_out',
    AllTicketsSoldOut: 'all_tickets_sold_out',
    ShowAlways: 'show_always',
} as const;

export type WaitingListTypesKeys = typeof WaitingListTypes[keyof typeof WaitingListTypes];

export const SignupSource = {
    Shop: 'shop',
} as const;

export type SignupSourceKeys = typeof SignupSource[keyof typeof SignupSource];

export interface WaitingListModel {
    guid: string;
    event_id: string;
    company_id: string;
    enabled: boolean;
    allow_quantity: boolean;
    allow_reseller_ticketswap?: boolean;
    allow_reseller_tixel?: boolean;
    type: WaitingListTypesKeys;
}

export interface SignupModel {
    event_id: string;
    shop_id: string;
    email: string;
    first_name: string;
    last_name: string;
    allow_marketing: boolean;
    quantity: number;
    source: SignupSourceKeys;
    locale?: string;
    reseller_ticketswap_enabled?: boolean;
    reseller_tixel_enabled?: boolean;
}

export const WAITING_LIST_ROUTE_NAME = 'waitinglist';

export async function createWaitingListSignup(
    waitingListId: string,
    signup: SignupModel
): Promise<SignupModel> {
    if (!process.env.VUE_APP_WAITING_LIST_URL) {
        return Promise.reject(new Error('Waiting list URL not specified'));
    }

    const path = urlJoin(
        process.env.VUE_APP_WAITING_LIST_URL,
        'waiting_list',
        encodeURIComponent(waitingListId),
        'signup'
    );

    const signupResponse: SignupModel | null = await axiosRequestWithValidation<
        SignupModel
    >(path, axios.post, signup);

    if (signupResponse) {
        return signupResponse;
    }

    return Promise.reject(new Error('Failed to signup'));
}

export async function getWaitingList(
    eventGuid: string
): Promise<WaitingListModel | null> {
    if (!process.env.VUE_APP_WAITING_LIST_URL) {
        return null;
    }

    const path = urlJoin(
        process.env.VUE_APP_WAITING_LIST_URL,
        'event',
        encodeURIComponent(eventGuid),
        'waiting_list'
    );

    return await axiosRequestWithValidation<WaitingListModel>(path);
}

export async function getSignupRules(): Promise<Rules | null> {
    if (!process.env.VUE_APP_WAITING_LIST_URL) {
        return null;
    }

    const path = urlJoin(
        process.env.VUE_APP_WAITING_LIST_URL,
        'signup',
        'rules'
    );

    return await axiosRequestWithValidation<Rules>(path);
}

export function isKeyOfSignupModel(
    key: string,
    signupModel: SignupModel
): key is keyof SignupModel {
    return key in signupModel;
}

type AxiosFunction = {
    <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        config?: AxiosRequestConfig<D>
    ): Promise<R>;
    <T = any, R = AxiosResponse<T>, D = any>(
        url: string,
        data?: D,
        config?: AxiosRequestConfig<D>
    ): Promise<R>;
};

async function axiosRequestWithValidation<Type>(
    path: string,
    axiosFunc: AxiosFunction = axios.get,
    inputData?: unknown
): Promise<Type | null> {
    try {
        const {
            data,
        }: {
            data: Type;
        } = inputData
            ? await axiosFunc<Type>(path, inputData)
            : await axiosFunc<Type>(path);

        if (data) {
            return data;
        }

        return null;
    } catch (e) {
        // In this case, the proper type is not returned,
        // Therefore, we return null to indicate that the request failed
        return null;
    }
}

export function eventShouldShowWaitingList(
    type: WaitingListTypesKeys | null,
    event: Event
): boolean {
    if (type) {
        switch (type) {
            case WaitingListTypes.AllTicketsSoldOut:
                return event.tickets.every(
                    (ticket: Ticket) => ticket.status == 'sold_out'
                );
            case WaitingListTypes.OneOrMoreTicketsSoldOut:
                return event.tickets.some(
                    (ticket: Ticket) => ticket.status == 'sold_out'
                );
            case WaitingListTypes.ShowAlways:
                return true;
            default:
                return false;
        }
    }
    return false;
}
