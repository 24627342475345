export function intToDuration(seconds: number, highestOnly = false): string {
    const duration: string[] = [];

    const add = (unit: string, value: number) => {
        if (value && (!highestOnly || (highestOnly && !duration.length))) {
            if (highestOnly && unit !== 'seconds') {
                value = value + 1;
            }

            duration.push(`${value} ${unit}`);
        }
    };

    add('years', Math.floor(seconds / 31536000));
    add('days', Math.floor((seconds % 31536000) / 86400));
    add('hours', Math.floor(((seconds % 31536000) % 86400) / 3600));
    add('minutes', Math.floor((((seconds % 31536000) % 86400) % 3600) / 60));
    add('seconds', (((seconds % 31536000) % 86400) % 3600) % 60);

    let str = '';
    for (let i = 0; i < duration.length; i++) {
        if (i === 0) {
            str += `${duration[i]}`;
        } else if (i > 0 && i < duration.length - 1) {
            str += `, ${duration[i]}`;
        } else if (i === duration.length - 1) {
            str += ` and ${duration[i]}`;
        }
    }
    return str;
}
