import {
    CartProduct,
    CartTicket,
    GroupProduct,
    ShopProduct,
} from '@openticket/lib-shop';

/**
 * Returns the price of a product, either from the cart (if exists) otherwise from the shop.
 *
 * Warning: Only use this function if there is a single price. This function ensures that hidden and discounted
 * prices are correctly returned.
 *
 * @param groupProduct The product to get the price for
 * @param cartTicket The cart to check for the product
 */
export function getProductPrice(
    groupProduct: GroupProduct,
    cartTicket: CartTicket
): number {
    if (!groupProduct || !groupProduct.guid) {
        // If this happens, the price request can never reliably be shown anyway.
        // This is a critical error, but as there is nothing to show it for,
        // It does not need to break the flow.
        // i.e. Showing the next product should still work.
        return NaN;
    }

    if (cartTicket) {
        if (cartTicket.products && cartTicket.products.collection) {
            // Check whether the item is in the cart. As the cart is the most
            // up-to-date pricing and can differ from the shop pricing
            const cartTicketProduct:
                | CartProduct
                | undefined = cartTicket.products.collection.find(
                (cartProduct: CartProduct) =>
                    groupProduct.guid === cartProduct?.item?.guid
            );

            if (
                cartTicketProduct &&
                cartTicketProduct.pricing &&
                typeof cartTicketProduct.pricing.total_price === 'number'
            ) {
                return cartTicketProduct.pricing.total_price;
            }
        }

        if (
            cartTicket.item &&
            cartTicket.item.products &&
            cartTicket.item.products.all
        ) {
            const shopProduct:
                | ShopProduct
                | undefined = cartTicket.item.products.all.find(
                (ticketProduct: ShopProduct) =>
                    groupProduct.guid === ticketProduct.guid
            );

            if (
                shopProduct &&
                shopProduct.pricing &&
                typeof shopProduct.pricing.total_price === 'number'
            ) {
                return shopProduct.pricing.total_price;
            }
        }
    }

    if (
        groupProduct.item &&
        groupProduct.item.pricing &&
        typeof groupProduct.item.pricing.total_price === 'number'
    ) {
        return groupProduct.item.pricing.total_price;
    }

    // If this happens, the price request can never reliably be shown anyway.
    // This is a critical error, but as there is nothing to show it for,
    // It does not need to break the flow.
    // i.e. Showing the next product should still work.
    return NaN;
}
