














































































import Component from 'vue-class-component';
import PaymentMethodItem from '../components/PaymentMethodItem.vue';
import ShopModule, { ShopModuleValidationError } from './module';
import {
    CartTicket,
    PaymentMethod,
    PaymentMethodIssuer,
} from '@openticket/lib-shop';
import { CartPaymentMethod, CheckoutDetails } from '@openticket/lib-shop';
import { Inject, Watch } from 'vue-property-decorator';
import TrackingClient, { GTMEvent } from '@openticket/lib-tracking';

export const STORAGE_PAYMENT_METHOD_KEY = 'payment_method';
@Component({
    components: {
        PaymentMethodItem,
    },
})
export default class PaymentView extends ShopModule {
    /* SHOP MODULE */

    public isDirty = true;

    public scopes = ['payment'];

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        const response = this.$shop.cart.validator.paymentMethod(false, mutate);

        if (response.valid) {
            return null;
        }

        return {
            message: response.message,
            payload: response.errors,
        };
    }

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    hideFees = false;

    isFree = false;

    @Inject('tracking')
    tracking!: TrackingClient;

    mounted(): void {
        this.validate();

        this.$shop.cart.on('payment_method', () => {
            this.validate();
        });

        this.isFree = this.$shop.cart.checkout_details.total_price === 0;

        this.$shop.cart.on(
            'checkout',
            (path: string[], details: CheckoutDetails) => {
                if (details.total_price === 0) {
                    this.isFree = true;
                } else {
                    this.isFree = false;
                }
            }
        );
    }

    selectedMethod: CartPaymentMethod | null = null;
    selectedIssuer: PaymentMethodIssuer | null = null;
    selectedMethodCustomMessage: string | null = null;

    created(): void {
        this.selectedMethod = this.$shop.cart.paymentMethod;

        const referenceFees = {
            fixed: this.paymentMethods[0].pricing.fees.fixed,
            percentage: this.paymentMethods[0].pricing.fees.percentage,
        };

        this.hideFees = this.paymentMethods.every(
            (method: PaymentMethod) =>
                method.pricing.fees.fixed === referenceFees.fixed &&
                method.pricing.fees.percentage === referenceFees.percentage
        );

        const shop = {
            guid: this.$shop.data.guid as string,
            name: this.$shop.data.name as string,
        };

        const items = Object.values(this.$shop.cart.flatItems.tickets).map(
            (cartTicket: CartTicket) => ({
                id: cartTicket.item.guid,
                name: cartTicket.item.name,
                pricing: cartTicket.pricing,
            })
        );

        // Triggered on entering the payment page
        this.tracking.push(GTMEvent.checkout(shop, items, 2));
    }

    get paymentMethods(): PaymentMethod[] {
        return this.$shop.data.payment_methods.filter(
            (method: PaymentMethod) => {
                return !method.replacementPaymentMethodGuid;
            }
        );
    }

    isSelected(guid: string): boolean {
        // This is in place to 'hide' a payment method that is selected.
        // Instead the replacement payment method is shown.
        return (
            this.selectedMethod?.item.guid === guid ||
            this.selectedMethod?.item.replacementPaymentMethodGuid === guid
        );
    }

    async selectMethod(guid: string, issuerGuid?: string): Promise<void> {
        if (this.$shop.cart.paymentMethod.item.guid === guid && !issuerGuid) {
            return;
        }

        await this.$shop.cart.setPaymentMethod(guid, issuerGuid);

        this.selectedMethod = this.$shop.cart.paymentMethod;
        this.selectedIssuer = this.selectedMethod.issuer || null;

        const data: { [key: string]: string } = { guid };

        if (issuerGuid) {
            data['issuer'] = issuerGuid;
        }

        localStorage.setItem(STORAGE_PAYMENT_METHOD_KEY, JSON.stringify(data));
    }

    @Watch('selectedMethod', { deep: true })
    selectCustomMessage(): void {
        if (this.selectedMethod) {
            const name: string = this.selectedMethod.item.name
                .toLowerCase()
                .replace(/[^a-z0-9]/g, '');

            let slug = 'shop.components.payment.send_to_' + name;
            if (this.$t(slug) != slug) {
                // Translation exists
                this.selectedMethodCustomMessage = slug;
                return;
            }
        }

        this.selectedMethodCustomMessage = null;
        return;
    }

    get email(): string {
        return this.$shop.cart.booker.email.value || '';
    }

    get fullname(): string {
        const firstName = this.$shop.cart.booker.firstname.value;
        const lastName = this.$shop.cart.booker.lastname.value;
        return `${firstName} ${lastName}`;
    }
}
