













































































import { getSelectorClass } from '@/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import EventGroupItem from './EventGroupItem.vue';
import CardHeading from './CardHeading.vue';
import DateBlock from '@/components/DateBlock.vue';
import { Prop } from 'vue-property-decorator';
import {
    Collapse,
    Event,
    EventDate,
    EventItem,
    Ticket,
} from '@openticket/lib-shop';
import { ShopModuleShopContentModifiers } from '@/pages/shop/modules/module';
import { filterShopContentTickets } from '@/utils/contentFilters';
import EventSummaryHeader from '@/pages/shop/components/EventSummaryHeader.vue';
import EventGroupSoldOut from '@/pages/shop/components/EventGroupSoldOut.vue';
import {
    eventShouldShowWaitingList,
    getWaitingList,
    WaitingListModel,
    WaitingListTypesKeys,
} from '@/utils/waitingList';

@Component({
    components: {
        EventGroupSoldOut,
        EventSummaryHeader,
        CardHeading,
        EventGroupItem,
        DateBlock,
    },
})
export default class EventGroup extends Vue {
    @Prop() event!: Event;
    @Prop() eventDate!: EventDate | undefined;
    @Prop() shopContentModifiers!: ShopModuleShopContentModifiers | null;

    waitingListType: WaitingListTypesKeys | null = null;

    get eventSelectorClass(): string {
        return getSelectorClass('event', this.event.guid);
    }

    async created(): Promise<void> {
        const waitingList: WaitingListModel | null = await getWaitingList(
            this.event.guid
        );
        this.waitingListType = waitingList?.type || null;
    }

    get shouldShowSoldOut(): boolean {
        return eventShouldShowWaitingList(this.waitingListType, this.event);
    }

    get items(): EventItem[] {
        const items: EventItem[] = [];
        for (const item of this.event.items) {
            if (item.hidden) {
                continue;
            }

            if (item.type === 'collapse') {
                if (this.showCollapse(item.item as Collapse)) {
                    items.push(item);
                }
            } else {
                if (this.showTicket(item.item as Ticket)) {
                    items.push(item);
                }
            }
        }
        return items;
    }

    get eventTimeslot(): Date | undefined {
        // DD-4E3A - If the event has a date selection, force show selected day in the date block
        //  to prevent confusion with multiday tickets
        if (
            !!this.$shop.data &&
            !!this.$shop.data.greedy_date_selection &&
            !!this.$shop.data.filters.start &&
            !Number.isNaN(Date.parse(this.$shop.data.filters.start))
        ) {
            return new Date(this.$shop.data.filters.start);
        }
        return undefined;
    }

    showTicket(ticket: Ticket): boolean {
        if (ticket.hidden) {
            return false;
        }

        if (
            filterShopContentTickets(this.shopContentModifiers, [ticket])
                .length === 0
        ) {
            return false;
        }

        // necessary since tickets in a collapse can have been filtered out of the ticket list,
        // and so they should be hidden
        const isInTickets = this.event.tickets.some(
            (t: Ticket) => t.guid === ticket.guid
        );

        if (!isInTickets) {
            return false;
        }

        if (!this.eventDate) {
            return true;
        }

        for (const product of ticket.products.required) {
            for (const event_date of product.item.event_dates) {
                if (event_date.guid === this.eventDate.guid) {
                    return true;
                }
            }
        }

        return false;
    }

    showCollapse(collapse: Collapse): boolean {
        for (const ticket of collapse.tickets) {
            if (this.showTicket(ticket)) {
                return true;
            }
        }
        return false;
    }
}
