

































































import Vue from 'vue';
import Component from 'vue-class-component';
import CardHeading from './CardHeading.vue';
import DateBlock from '@/components/DateBlock.vue';
import { Prop } from 'vue-property-decorator';
import CollapseCard from '@/pages/shop/components/CollapseCard.vue';

@Component({
    components: {
        CollapseCard,
        CardHeading,
        DateBlock,
    },
})
export default class EventSummaryHeader extends Vue {
    @Prop() name!: string;
    @Prop({ default: null }) start!: Date | null;
    @Prop({ default: null }) end!: Date | null;
    @Prop({ default: null }) locationName!: string | null;
    @Prop({ default: null }) locationAddress!: string | null;
    @Prop({ type: Boolean, default: false }) showRightAction!: boolean;
    // DD-4E3A - If the event has a date selection, force show selected day in the date block
    //  to prevent confusion with multiday tickets
    @Prop({ type: Date }) eventDateTimeslot!: Date;

    get showDateBlock(): boolean {
        return (
            !this.$settings ||
            !this.$settings.static ||
            !this.$settings.static.shop ||
            this.$settings.static.shop.showDateBlock ||
            this.$settings.static.shop.showDateBlock === null
        );
    }
}
