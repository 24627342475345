































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CollapseCard from '@/pages/shop/components/CollapseCard.vue';
import { CartProduct } from '@openticket/lib-shop/lib/cart/item';
import { CheckoutDetails } from '@openticket/lib-shop';

@Component({
    components: { CollapseCard },
})
export default class CartReviewGlobalProduct extends Vue {
    globalProducts: CartProduct[] = [];

    checkoutListener!: string;

    showDetails = false;
    created(): void {
        // TODO check if we can remove this listener and get reactivity from CartReview
        this.checkoutListener = this.$shop.cart.on(
            'checkout',
            (path: string[], details: CheckoutDetails) => {
                if (!details.busy) {
                    this.updateValues();
                }
            }
        );

        this.updateValues();
    }

    updateValues(): void {
        this.globalProducts = [
            ...this.$shop.cart.orphanedGlobalProducts.collection,
        ];
    }

    destroyed(): void {
        this.$shop.off(this.checkoutListener);
    }
}
