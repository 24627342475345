export function matchItem<T extends { guid: string; [key: string]: any }>(
    item: T,
    filters: ({ [key: string]: string } | string)[]
): boolean {
    for (const filter of filters) {
        // GUID match
        if (typeof filter === 'string') {
            if (item.guid === filter) {
                return true;
            }

            // REGEX match
        } else {
            if (Object.keys(filter).length < 1) {
                throw Error('Regex filter contains no properties');
            }

            let matchAll = true;

            for (const prop of Object.keys(filter)) {
                const exp = new RegExp(filter[prop]);

                if (typeof item[prop] === 'string') {
                    const match = exp.test(item[prop]);

                    if (!match) {
                        matchAll = false;
                        break;
                    }
                }
            }

            if (matchAll) {
                return true;
            }
        }
    }

    return false;
}

export function excludeItems<T extends { guid: string; [key: string]: any }>(
    items: T[],
    filters: ({ [key: string]: string } | string)[]
): T[] {
    const filteredItems: T[] = [];

    for (const item of items) {
        if (!matchItem<T>(item, filters)) {
            filteredItems.push(item);
        }
    }

    return filteredItems;
}

export function findMatch<T extends { guid: string; [key: string]: any }>(
    items: T[],
    filters: ({ [key: string]: string } | string)[]
): T | undefined {
    for (const item of items) {
        if (matchItem<T>(item, filters)) {
            return item;
        }
    }
}
