






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CartTicket, ProductGroup } from '@openticket/lib-shop';
import { getProductPrice } from './pricing';

interface CheckboxGroupOptions {
    label: string;
    suffix: string;
    description?: string;
}

@Component
export default class OptionalProductGroupCheckboxItem extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;

    checked: string[] = [];
    productListener!: string;
    options: { [key: string]: CheckboxGroupOptions } = {};

    created(): void {
        this.update();

        this.productListener = this.$shop.cart.on(
            ['ticket', this.ticket.id, 'product'],
            () => {
                this.update();
            }
        );
    }

    update(): void {
        this.updateChecked();
        this.updateOptions();
    }

    destroyed(): void {
        if (this.productListener) {
            this.$shop.cart.off(this.productListener);
        }
    }

    @Watch('ticket.item', { deep: true, immediate: true })
    updateOptions(): void {
        const map: { [key: string]: CheckboxGroupOptions } = {};
        for (const product of this.productGroup.products) {
            map[product.guid] = {
                label: product.item.name,
                suffix: this.$l.currency(
                    getProductPrice(product, this.ticket),
                    this.$shop.data.currency as string
                ),
                description: product.item.description,
            };
        }

        this.options = map;
    }

    updateChecked(): void {
        const checked: string[] = [];
        for (const product of this.productGroup.products) {
            for (const cartProduct of this.ticket.products.collection) {
                if (product.guid === cartProduct.item.guid) {
                    checked.push(product.guid);
                }
            }
        }
        this.checked = checked;
    }

    onInputChanged(newValue: string[]): void {
        for (const guid of [...newValue, ...this.checked]) {
            if (!newValue.includes(guid)) {
                this.$emit('remove', guid);
            } else if (!this.checked.includes(guid)) {
                this.$emit('add', guid);
            }
        }
    }
}
