












import Component from 'vue-class-component';
import EventGroupItemTicket from '@/pages/shop/components/EventGroupItemTicket.vue';
import ShopModuleComponent from '../../module';
import { CartTicket } from '@openticket/lib-shop';

@Component({
    components: {
        EventGroupItemTicket,
    },
})
export default class IndividualNoTicketMetadata extends ShopModuleComponent {
    /* SHOP MODULE */

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    get tickets(): CartTicket[] {
        return Object.values(this.$shop.cart.flatItems.tickets).filter(
            (cartTicket: CartTicket) => {
                return (
                    cartTicket.metadataList.length > 0 ||
                    cartTicket.item.products.optional.length > 0
                );
            }
        );
    }
}
