































import FrameBreak from '@/pages/shop/components/FrameBreak.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { Mutation, State } from 'vuex-class';
import { SET_PAGES } from './store';
import { ShopModuleNotReady } from '@/pages/shop/modules/module';
import {
    EventSelection,
    IShopConfig,
    LogLevel,
    LogMessage,
    OpenTicketShop,
    ReservationExpiration,
    ShopFilter,
    State as ShopState,
} from '@openticket/lib-shop';
import { hasLocalStorage, LocalStorage } from '../../utils';
import TimeoutModal from './modals/Timeout.vue';
import { STORAGE_PAYMENT_METHOD_KEY } from './modules/Payment.vue';
import Footer from '@/components/Footer.vue';
import { Inject } from 'vue-property-decorator';
import {
    CustomShopSettingsPage,
    CustomShopSettingsStatic,
} from '@openticket/lib-custom-shop-settings';
import defaultPages from './pages.json';
import { BaseInit, OverlayManager } from '@/Base.vue';
import { StyleTheme } from '@openticket/lib-style';
import { NotificationConfig } from '@openticket/vue-notifications';
import ClosedView from './views/Closed.vue';
import Tracking from './Tracking.vue';
import { ShopTweakConfigModule, ShopTweakConfigPage } from './store/types';
import CookieWall from '@/components/cookies/CookieWall.vue';
import { getCookieMessages, initCookies } from '@/pages/shop/cookies';
import { WAITING_LIST_ROUTE_NAME } from '@/utils/waitingList';

const DEFAULT_SHOP_API_URL = 'https://shop.api.openticket.tech';

interface CachedPaymentMethod {
    guid: string;
    issuer?: string;
}

function pageHasAnyModule(
    page: CustomShopSettingsPage,
    module: string,
    ...modules: string[]
): boolean {
    const moduleNames: [string, ...string[]] = [module, ...modules];

    return (
        !!page &&
        page.modules.some((module: string | ShopTweakConfigModule) => {
            return moduleNames.includes(
                typeof module === 'string' ? module : module.name
            );
        })
    );
}

function transformQueryForBackwardsCompatibility(
    $router: VueRouter,
    $route: Route
) {
    // Ugly fix for backwards compatibility
    try {
        if (!$route.query.event && $route.query.eventId) {
            const { eventId } = $route.query;

            const query: Route['query'] = {
                ...$route.query,
                event: eventId,
            };
            delete query.eventId;

            void $router.replace({ query });
        }
    } catch (e) {
        // No-op
    }
}

@Component({
    components: {
        CookieWall,
        FrameBreak,
        ClosedView,
        Tracking,
        Footer,
        TimeoutModal,
    },
})
export default class ShopView extends Vue {
    @Mutation(SET_PAGES)
    setPages!: (pages: ShopTweakConfigPage[]) => void;

    @State('pages')
    pages!: ShopTweakConfigPage[];

    @Inject('overlay')
    overlay!: OverlayManager;

    @Inject('baseInit')
    baseInit!: BaseInit;

    waiting = false;
    initialized = false;
    initError: LogMessage | null = null;
    preferredEntryPage: string | null = null;
    breakFrame = false;
    showTimeoutModal = false;

    triggerCookieWallOpen = 0;

    closeOverlay!: () => void;
    cookiesPromise: null | Promise<void> = null;

    async created(): Promise<void> {
        transformQueryForBackwardsCompatibility(this.$router, this.$route);

        this.closeOverlay = this.overlay.show();

        // Listen for notification events, only show when not embedded
        this.$shop.events.on(
            ['notification'],
            (_path: string[], data: NotificationConfig) => {
                let notification!: NotificationConfig;
                if (data.slug) {
                    if (typeof data.slug === 'string') {
                        if (typeof data.slugCount === 'number') {
                            notification = {
                                type: data.type,
                                message: this.$tc(
                                    data.slug,
                                    data.slugCount,
                                    data.slugData
                                ) as string,
                            };
                        } else {
                            notification = {
                                type: data.type,
                                message: this.$t(
                                    data.slug,
                                    data.slugData
                                ) as string,
                            };
                        }
                    } else {
                        notification = {
                            type: data.type,
                            message: {
                                text:
                                    typeof data.slugCount === 'object' &&
                                    data.slugCount.text
                                        ? (this.$tc(
                                              data.slug.text,
                                              data.slugCount.text,
                                              data.slugData
                                          ) as string)
                                        : (this.$t(
                                              data.slug.text,
                                              data.slugData
                                          ) as string),
                                subText:
                                    typeof data.slugCount === 'object' &&
                                    data.slugCount.subText
                                        ? (this.$tc(
                                              data.slug.subText,
                                              data.slugCount.subText,
                                              data.slugData
                                          ) as string)
                                        : (this.$t(
                                              data.slug.subText,
                                              data.slugData
                                          ) as string),
                            },
                        };
                    }
                } else {
                    if (typeof data.message === 'string') {
                        notification = {
                            type: data.type,
                            message: data.message,
                        };
                    } else {
                        notification = {
                            type: data.type,
                            message: data.message,
                        };
                    }
                }

                if (!this.$shop.isFramed) {
                    this.$notifications.show(notification);
                } else {
                    this.$shop.sendClientNotification(notification);
                }
            }
        );

        this.$shop.events.on(
            ['settings', 'static'],
            (_, settings: CustomShopSettingsStatic) => {
                if (this.$settings) {
                    this.$settings.localUpdateStaticSettings(settings);

                    this.$style.setTheme(
                        this.$settings.static.theme as StyleTheme
                    );

                    if (this.$settings.static.style) {
                        this.$style.setStyle(this.$settings.static.style);
                    }
                }
            }
        );

        if (this.$route.params.page) {
            this.preferredEntryPage = this.$route.params.page;
        }

        const shop_id = this.$route.params.shop_id;

        const baseInitPromise = this.baseInit(shop_id);

        // Create config object with shop id from the URL
        const config: IShopConfig = this.createConfig(shop_id);

        // Create filters for dates if the shop is a timeslot shop
        const filters: ShopFilter | undefined = this._determineFilters();

        // Start initialization of the shop
        try {
            // Setup the shop structure and fetch the initial shop data
            await this.$shop.init(config, filters);
        } catch (error) {
            this.closeOverlay();

            // Ignore any errors here, they will be handled by _finishInitialization
            this.waiting = this.$shop.state === ShopState.Waiting;

            if (
                error &&
                error._isOpenTicketLogMessage &&
                error.slug === 'sdkshop.log.shop.init.error.fatal' &&
                error.parent &&
                error.parent.slug === 'sdkshop.log.shop.data.error.notfound'
            ) {
                await this.$router.push({
                    name: 'error',
                    query: { redirect: this.$route.path },
                });

                return;
            }

            if (!this.waiting) {
                // TODO Show some kind of error???
                //  as the loading screen is shown in this case...
                //  probably show some overlay like dashboard ???

                await this.$router.push({
                    name: 'error',
                    query: { redirect: this.$route.path },
                });

                throw error;
            }

            return;
        }

        // Set the initial document title early
        // Will already update the page title if the shop is only partially initialized.
        if (this.$shop.data && this.$shop.data.name) {
            document.title = this.$shop.data.name;
        }

        await baseInitPromise;

        if (this.detectFrameBreak()) {
            this.breakFrame = true;

            this.$nextTick(() => {
                this.closeOverlay();
            });

            return;
        }

        this.$localization.on('locale-change', () => {
            this.$cookies.setMessages(getCookieMessages(this));
        });

        this.cookiesPromise = initCookies(
            this.$shop,
            this.$cookies,
            getCookieMessages(this)
        );

        // Set some properties based on the tweaks
        this.initShopPages();

        // Let component initialization finish!
        this.$nextTick(() => {
            this._finishInitialization();
        });
    }

    // If we are in a deep linked state, and we do not have a first page loaded, we should do so if required
    beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): void {
        if (
            from.name === WAITING_LIST_ROUTE_NAME &&
            to.name === 'step' &&
            !to.params.page
        ) {
            next({
                name: 'step',
                params: {
                    page: this.computeFirstPage(),
                },
                query: {
                    ...this.$route.query,
                    waitingListEventId: undefined,
                },
            });
            return;
        }
        next();
    }

    async _finishInitialization(): Promise<void> {
        try {
            // Await shop initialization (this will also wait for suspended initialization)
            await this.$shop.initialized;
        } catch (error) {
            this.initError = error;

            this.closeOverlay();

            if (this.$shop.initErrors.length) {
                await this.$router.push({
                    name: 'closed',
                    query: {
                        redirect: this.$route.path,
                    },
                });
            } else {
                await this.$router.push({
                    name: 'error',
                    query: {
                        redirect: this.$route.path,
                    },
                });
            }

            return;
        } finally {
            this.waiting = false;
        }

        this.initError = null;

        this.$shop.cart.setLocale(this.$localization.locale.locale);

        await this._checkCouponParam();

        this._checkShopCodeParam();

        if (this.$shop.data.company.timezone) {
            this.$localization.setTimezone(this.$shop.data.company.timezone);
        }

        // TODO Add shop data locale to localize defaults -> which should trigger a change if it needs to!.

        // (Re-)set the document title
        document.title = this.$shop.data.name || '';
        this.$shop.events.on(
            ['shop', 'locale'],
            async (_path: string[], locale: string) => {
                await this.$localization.setLocale(locale);
            }
        );

        this.$shop.events.on(
            ['timer', 'clear'],
            async (
                path: string[],
                data: ReservationExpiration & { timeout: boolean }
            ) => {
                if (!data.timeout) {
                    return;
                }

                await this.pushFirstPage();
                this.showTimeoutModal = true;
            }
        );

        await this.useLastUsedPaymentMethod();

        try {
            await this.pushFirstPage();
        } finally {
            this.initialized = true;
        }

        if (this.$route.query.sessionId) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    sessionId: undefined,
                },
            });
        }

        this.$nextTick(() => {
            this.closeOverlay();
        });

        await this.cookiesPromise;
    }

    /**
     *  Used to check if a coupon param has been set, and add the coupon if so.
     */
    async _checkCouponParam(): Promise<void> {
        const coupon = this.$route.query.coupon as string | null;

        if (!coupon || !coupon.length) {
            return;
        }

        try {
            await this.$shop.cart.addCoupon(coupon);
        } catch {
            // Fail silently when the coupon seems invalid
        }
    }

    /**
     *  Used to check if the shop_code param has been set, and
     *  set it as tech data in the shop (SDK) if so.
     */
    _checkShopCodeParam(): void {
        const shop_code = this.$route.query.shop_code as string | null;

        if (!shop_code || !shop_code.length) {
            return;
        }

        this.$shop.cart.setTechData({ shop_code });
    }

    async pushFirstPage(): Promise<Route | void> {
        // If deeplinking into waitinglist we do not want to push first page
        if (this.$route.name === WAITING_LIST_ROUTE_NAME) {
            return;
        }

        try {
            return await this.$router.replace({
                name: 'step',
                params: {
                    page: this.computeFirstPage(),
                },
                query: this.$route.query,
            });
        } catch (e) {
            if (
                !e.from ||
                !e.to ||
                e.from.name !== e.to.name ||
                !e.from.params ||
                !e.to.params ||
                e.from.params.page !== e.to.params.page
            ) {
                throw e;
            }

            // Ignore navigation duplicated error if the page is the same.
        }
    }

    computeFirstPage(): string {
        const defaultPageName: string = this.pages[0].name;

        try {
            if (!this.preferredEntryPage) {
                return defaultPageName;
            }

            const preferredPageIndex: number = this.pages.findIndex(
                (page: ShopTweakConfigPage) =>
                    page.name === this.preferredEntryPage
            );

            if (preferredPageIndex < 0) {
                return defaultPageName;
            }

            const previousPages: ShopTweakConfigPage[] = this.pages.slice(
                0,
                preferredPageIndex
            );

            let currentPageName: string = defaultPageName;

            // Currently, only the events-module is skippable.
            // Other modules require validation, which is currently a stateful operation.
            // Once this is a stateless (or at least does not require initialized and mounted components)
            // this could be changed to just allow all modules to be skipped,
            // as long as they validate successfully
            const skippableModules: string[] = ['events'];

            // Test every module for every page
            // It should both ready and valid, if a module is not.
            // That page will become the first page.
            // Even if a next page is desired.
            for (const previousPage of previousPages) {
                if (!previousPage.modules) {
                    // This is a weird edge case. if there are no modules on a page,
                    // it is undefined what behaviour should happen.
                    // Therefore, just let the user walk through the pages from the page before this.
                    return currentPageName;
                }

                currentPageName = previousPage.name;

                for (const module of previousPage.modules) {
                    const moduleName: string =
                        typeof module === 'string' ? module : module.name;

                    // Events module (or the next page/modules) do not validate if an event was actually chosen if it had to.
                    // So this is a custom check for the entry point
                    if (moduleName === 'events' && !this.$route.query.event) {
                        return currentPageName;
                    }

                    if (!skippableModules.includes(moduleName)) {
                        return currentPageName;
                    }

                    const moduleComponent:
                        | undefined
                        | {
                              isReady?: (
                                  shop: OpenTicketShop
                              ) => ShopModuleNotReady | null;
                          } =
                        // @ts-expect-error - options is where globally registered components are held.
                        Vue.options.components[`shop-module-${moduleName}`];

                    if (!moduleComponent || !moduleComponent.isReady) {
                        return currentPageName;
                    }

                    const notReadyError: ShopModuleNotReady | null = moduleComponent.isReady(
                        this.$shop
                    );

                    if (notReadyError) {
                        return currentPageName;
                    }
                }
            }

            return this.preferredEntryPage;
        } catch (e) {
            console.error(e);

            return defaultPageName;
        }
    }

    _determineFilters(): ShopFilter | undefined {
        const query = this.$route.query;

        const filters = ['start', 'end'];

        const map: ShopFilter = {};

        for (const filter of filters) {
            if (query[filter]) {
                map[filter] = query[filter] as string;
            }
        }

        if (Object.keys(map).length < 1) {
            return undefined;
        }

        return map;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Create the basic shop config object
     */
    createConfig(shopId: string): IShopConfig {
        return {
            baseUrl: process.env.VUE_APP_SHOP_API_URL || DEFAULT_SHOP_API_URL,
            guid: shopId,
            logLevels: {
                console: LogLevel.Debug,
                service: LogLevel.Info,
            },
            cseUrls: {
                surchargeUrl: process.env.VUE_APP_SURCHARGE_URL,
                externalCardFieldsUrl:
                    process.env.VUE_APP_EXTERNAL_CARD_FIELDS_URL,
            },
            loggerUrl: process.env.VUE_APP_LOGGER_URL,
        };
    }

    /**
     * Determine if a frame break is needed
     */
    detectFrameBreak(): boolean {
        if (!this.$settings || !this.$settings.static.shop.blockFrameAccess) {
            return false;
        }

        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    /**
     * Create and initialize the shop tweak data
     */
    initShopPages(): void {
        let pages!: CustomShopSettingsPage[];

        // Check if ShopSettingsClient exists
        if (
            !this.$settings ||
            !this.$settings.dynamic ||
            !this.$settings.dynamic.shop ||
            !this.$settings.dynamic.shop.pages
        ) {
            // If not, get the default pages
            pages = this.transformShopPages();
        } else {
            // Get pages from custom settings
            pages = this.transformShopPages(this.$settings.dynamic.shop.pages);
        }

        // Set the pages in the vuex instance
        this.setPages(pages);
    }

    /**
     * Transform the pages provided by the tweaks to a more consistent format
     */
    transformShopPages(
        customPages?: CustomShopSettingsPage[]
    ): CustomShopSettingsPage[] {
        const pages: CustomShopSettingsPage[] = customPages || defaultPages;

        // Loop over all pages to transform all modules with type string to
        // objects containing the name property
        for (const page of pages) {
            const modules = page.modules || [];

            for (let i = 0; i < modules.length; i++) {
                if (typeof modules[i] === 'string') {
                    modules[i] = {
                        name: modules[i] as string,
                    };
                }
            }

            page.modules = modules;
        }

        // Add an events page if the shop is not a timeslot shop
        // Changing this can cause unintended side-effects in the timeslot placement
        if (!this.$shop.data.greedy_date_selection && this.hasEventSelection) {
            const eventPage: ShopTweakConfigPage & CustomShopSettingsPage = {
                name: 'events',
                title: 'shop.pages.shop.events',
                hideOrderBar: true,
                modules: [
                    {
                        name: 'events',
                    },
                ],
            };

            if (
                pages[0] &&
                pageHasAnyModule(pages[0], 'external-validation-form') &&
                !pageHasAnyModule(pages[0], 'tickets', 'products')
            ) {
                // Note, if the external-validation-form results in a filtered shop,
                // which would have changed constraints for the event selection boolean,
                // this is currently NOT taken into account.
                // i.e. When the event selection is set to auto and after the external validation
                // form the number of events is less than 4.
                return [pages[0], eventPage, ...pages.slice(1)];
            }

            return [eventPage, ...pages];
        }

        return pages;
    }

    get hasEventSelection(): boolean {
        switch (this.$shop.data.event_selection) {
            case EventSelection.Enabled:
                return true;

            case EventSelection.Disabled:
                return false;

            default:
                return (
                    this.$shop.data.events.length > 3 &&
                    !this.$shop.data.greedy_date_selection
                );
        }
    }

    /**
     *  If a payment method was used before,
     *  it is applied to the cart.
     *
     *  Cleans up the cached value if an exception occurs.
     */
    async useLastUsedPaymentMethod(): Promise<void> {
        if (!hasLocalStorage()) {
            return;
        }

        try {
            const cachedMethod: CachedPaymentMethod | null = LocalStorage.get<
                CachedPaymentMethod
            >(STORAGE_PAYMENT_METHOD_KEY);

            if (!cachedMethod) {
                return;
            }

            if (cachedMethod.guid in this.$shop.data.payment_methods_map) {
                await this.$shop.cart.setPaymentMethod(
                    cachedMethod.guid,
                    cachedMethod.issuer
                );
            }
        } catch (e) {
            LocalStorage.remove(STORAGE_PAYMENT_METHOD_KEY);
        }
    }

    get showPoweredBy(): boolean {
        return (
            !this.$settings ||
            !this.$settings.static ||
            !this.$settings.static.shop ||
            !this.$settings.static.shop.footer ||
            this.$settings.static.shop.footer.showPoweredBy ||
            this.$settings.static.shop.footer.showPoweredBy === null
        );
    }

    get showLocaleSwitch(): boolean {
        return (
            !this.$settings ||
            !this.$settings.static ||
            !this.$settings.static.shop ||
            !this.$settings.static.shop.footer ||
            this.$settings.static.shop.footer.showLocaleSwitcher ||
            this.$settings.static.shop.footer.showLocaleSwitcher === null
        );
    }
}
