import { ShopData, ShopProduct } from '@openticket/lib-shop';

export function getGlobalProduct(
    data: ShopData,
    className: string,
    eventId?: string
): ShopProduct | undefined {
    for (const product of data.global_products) {
        if (product.item.class === className) {
            return product;
        }
    }

    if (eventId) {
        for (const product of data.events_map[eventId].global_products) {
            if (product.item.class === className) {
                return product;
            }
        }
    } else {
        for (const event of data.events) {
            for (const product of event.global_products) {
                if (product.item.class === className) {
                    return product;
                }
            }
        }
    }
}
