



































































































import Component from 'vue-class-component';
import OptionalItem from '@/pages/shop/components/OptionalProductGroup/OptionalItem.vue';
import { Prop, Watch } from 'vue-property-decorator';
import ShopModuleComponent, { ShopModuleValidationError } from '../../module';
import CollapseCard from '@/pages/shop/components/CollapseCard.vue';
import {
    CartMetadata,
    CartTicket,
    GroupProduct,
    Product,
    ProductGroup,
} from '@openticket/lib-shop';
import MarkdownRenderer from '@/pages/shop/components/MarkdownRenderer.vue';

@Component({
    components: {
        MarkdownRenderer,
        OptionalItem,
        CollapseCard,
    },
})
export default class SwinkelsGroupMenu extends ShopModuleComponent {
    @Prop() productGroupNames!: string[];
    @Prop() assets!: string;

    @Prop() selectMetadataGuid!: string;
    @Prop() selectMetadataHideValue!: string;

    @Prop() commentMetadataGuid!: string;

    cartTicket!: CartTicket;

    selected: Product | null = null;
    loading = false;

    created(): void {
        this.cartTicket = Object.values(this.$shop.cart.flatItems.tickets)[0];

        for (const product of this.productGroup.products) {
            const info = this.$shop.cart.getTicketProductInfo(
                this.cartTicket.id,
                product.guid
            );
            if (info.count > 0) {
                this.selected = this.$shop.data.products_map[product.guid];
            }
        }
    }

    public static isReady(): null {
        return null;
    }

    public validate(): ShopModuleValidationError | null {
        return null;
    }

    productImage(product: GroupProduct): string {
        return (
            this.assets +
            this.productName(product)
                .toLowerCase()
                .replace(/([A-Z])/g, ' $1')
                .split(' ')
                .join('_') +
            '.png'
        );
    }

    productName(product: GroupProduct): string {
        return product.item.name.split('(')[0].trim();
    }

    productPrice(product: GroupProduct): string {
        return product.item.name
            .split('(')[1]
            .split(')')[0]
            .trim();
    }

    async removeProducts(): Promise<void> {
        for (const product of this.productGroup.products) {
            const info = this.$shop.cart.getTicketProductInfo(
                this.cartTicket.id,
                product.guid
            );
            if (info.count > 0) {
                for (const cartProduct of info.items) {
                    this.$shop.cart
                        .removeProductFromTicket(
                            this.cartTicket.id,
                            product.guid,
                            cartProduct.id
                        )
                        .then(() => {
                            /* No-op */
                        });
                }
            }
        }
        this.selected = null;
    }

    async selectProduct(product: GroupProduct): Promise<void> {
        this.loading = true;

        try {
            await this.removeProducts();

            await this.$shop.cart.addProductToTicket(
                this.cartTicket.id,
                product.guid
            );
            this.selected = product.item;
        } finally {
            this.loading = false;
        }
    }

    get productGroup(): ProductGroup {
        for (const group of this.$shop.data.groups) {
            if (!group.name) {
                continue;
            }

            if (this.productGroupNames.includes(group.name)) {
                return group;
            }
        }

        throw Error('ProductGroup not found for menu');
    }

    get selectMetadata(): CartMetadata {
        return this.$shop.cart.metadata[this.selectMetadataGuid];
    }

    @Watch('selectMetadata.value')
    async onSelectMetadataChange(value: string): Promise<void> {
        if (value === this.selectMetadataHideValue) {
            await this.removeProducts();
        }
    }
}
